<template>
    <div class="homeOrder">
        <div class="title">我的浏览</div>

        <div class="tabss">
            <!-- <el-tabs v-model="activeName" @tab-click="handleClick" style="width: 97%;">
                <el-tab-pane label="按时间排序" name="first">
                    <div class="cont">
                        <div class="son" :class="[managementShow ? 'managementClass' : '']"
                            v-for="(i, index) in browsingHistoryList" :key="index"
                            :style="{ border: !isSelected ? '1px solid #FF4242 !important' : '' }">
                            <div class="img">
                                <img :src="i.img" alt="">
                            </div>
                            <div class="name">{{ i.title }}</div>
                            <div class="jiag">
                                <div class="jg">¥{{ i.price }}</div>
                                <div class="xl">销量 {{ i.sales }}</div>
                            </div>
                            <div class="img2" v-if="managementShow" @click="isSelected = !isSelected">
                                <img v-if="isSelected" src="@/assets/icon/wxz.png" alt="">
                                <img v-else src="@/assets/icon/xuanz.png" alt="">
                            </div>
                        </div>

                        <div style="width: 100%;display: flex;justify-content: space-between;" v-if="managementShow">
                            <div class="mrdz" @click="isSelected = !isSelected">
                                <div class="img">
                                    <img v-if="isSelected" src="@/assets/icon/wxz.png" alt="">
                                    <img v-else src="@/assets/icon/xuanz.png" alt="">
                                </div>
                                <div class="text" v-html="isSelected ? '全选' : '全不选'"></div>
                            </div>
                            <div class="tn">取消收藏</div>
                        </div>
                    </div>

                    <paging :total="paging.total" @handleCurrentChange="handleCurrentChange" :page-size="10"
                        :currentPage="paging.page"></paging>
                </el-tab-pane>



                <el-tab-pane label="按销量排序" name="second">
                    <div class="cont">
                        <div class="son" :class="[managementShow ? 'managementClass' : '']" v-for="(i, index) in browsingHistoryList"
                            :key="index" :style="{ border: !isSelected ? '1px solid #FF4242 !important' : '' }">
                            <div class="img">
                                <img :src="i.img" alt="">
                            </div>
                            <div class="name">{{ i.title }}</div>
                            <div class="jiag">
                                <div class="jg">¥{{ i.price }}</div>
                                <div class="xl">销量 {{ i.sales }}</div>
                            </div>
                            <div class="img2" v-if="managementShow" @click="isSelected = !isSelected">
                                <img v-if="isSelected" src="@/assets/icon/wxz.png" alt="">
                                <img v-else src="@/assets/icon/xuanz.png" alt="">
                            </div>
                        </div>

                        <div style="width: 100%;display: flex;justify-content: space-between;" v-if="managementShow">
                            <div class="mrdz"  @click="isSelected = !isSelected">
                                <div class="img">
                                    <img v-if="isSelected" src="@/assets/icon/wxz.png" alt="">
                                    <img v-else src="@/assets/icon/xuanz.png" alt="">
                                </div>
                                <div class="text" v-html="isSelected ? '全选' : '全不选'"></div>
                            </div>
                            <div class="tn">取消收藏</div>
                        </div>
                    </div>
                    <paging :total="paging.total" @handleCurrentChange="handleCurrentChange" :page-size="10"
                    :currentPage="paging.page"></paging>

                </el-tab-pane>
            </el-tabs> -->
            <!-- <div class="gl" @click="managementShow = !managementShow">管理</div> -->

            <div class="cont">
                <div class="son" :class="[managementShow ? 'managementClass' : '']" @click="commClick(i)"
                    v-for="(i, index) in browsingHistoryList" :key="index"
                    :style="{ border: !isSelected ? '1px solid #FF4242 !important' : '' }">
                    <div class="img">
                        <img :src="i.img" alt="">
                    </div>
                    <div class="name">{{ i.title }}</div>
                    <div class="jiag">
                        <div class="jg">¥{{ i.price }}</div>
                        <div class="xl">销量 {{ i.sales }}</div>
                    </div>
                    <div class="img2" v-if="managementShow" @click="isSelected = !isSelected">
                        <img v-if="isSelected" src="@/assets/icon/wxz.png" alt="">
                        <img v-else src="@/assets/icon/xuanz.png" alt="">
                    </div>
                </div>
                <div v-if="browsingHistoryList.length == 0"
                    style="width: 100%;text-align: center;margin: 50px auto;color: #666;display: flex;justify-content: center;flex-wrap: wrap;">
                    <img src="@/assets/wsj.png" style="width: 250px;object-fit: contain;" alt="">
                    <div style="width: 100%;margin-top: 10px;">暂无数据</div>
                </div>

                <div style="width: 100%;display: flex;justify-content: space-between;" v-if="managementShow">
                    <div class="mrdz" @click="isSelected = !isSelected">
                        <div class="img">
                            <img v-if="isSelected" src="@/assets/icon/wxz.png" alt="">
                            <img v-else src="@/assets/icon/xuanz.png" alt="">
                        </div>
                        <div class="text" v-html="isSelected ? '全选' : '全不选'"></div>
                    </div>
                    <div class="tn">取消收藏</div>
                </div>
            </div>

            <!-- 分页 -->
            <paging :total="paging.total" @handleCurrentChange="handleCurrentChange" :page-size="paging.sizi"
                :currentPage="paging.page"></paging>
        </div>
    </div>
</template>

<script>
import { getBrowsingHistory } from '@/utils/api/myApi/index'
import paging from "@/components/paging.vue";
export default {
    name: 'myBrowsingHistory',
    components: {
        paging
    },
    data() {
        return {
            activeName: 'first',
            // 管理
            managementShow: false,
            isSelected: true,
            // 分页
            paging: {
                sizi: 12,
                page: 1,
                total: 0
            },
            // 浏览列表
            browsingHistoryList: []
        }
    },
    mounted() {
        this.getBrowsing()
    },
    methods: {
        // 点击跳转商品
        commClick(i) {
            window.open(`#/commodity/productDetails?ids=${i.spuId}`, '_blank');
        },
        handleClick(tab, event) {
            console.log(tab.index);
        },
        // 获取浏览记录列表
        async getBrowsing() {
            const res = await getBrowsingHistory({
                currentPage: this.paging.page - 1,
                pageSize: this.paging.sizi,
            })
            if (res.statusCode == 8201) {
                this.browsingHistoryList = res.data.content
                this.paging.total = res.data.totalElements
            }
        },
        // 分页事件
        handleCurrentChange(val) {
            this.loading = true
            this.paging.page = val 
            this.getBrowsing()
        },
    }
}
</script>

<style lang="less" scoped>
.homeOrder {
    width: 1050px;
    height: auto;

    >.title {
        width: 100%;
        height: 40px;
        background-color: #fff;
        padding: 10px 15px;
        box-sizing: border-box;
        font-size: 16px;
        color: #333;
        font-weight: bold;
    }

    >.tabss {
        width: 100%;
        height: auto;
        padding: 30px 20px;
        box-sizing: border-box;
        background-color: #fff;
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        >.gl {
            font-size: 14px;
            color: #333;
            cursor: pointer;
            user-select: none;
        }
    }

    .cont {
        width: 100%;
        height: auto;
        display: flex;
        // justify-content: space-between;
        flex-wrap: wrap;
        gap: 20px;

        >.son:hover {
            border: 1px solid #FF4242;
            transition: all .3s;
        }

        >.son {
            width: 220px;
            height: 280px;
            padding: 10px;
            box-sizing: border-box;
            margin-bottom: 10px;
            border-radius: 4px;
            border: 1px solid #fff;
            position: relative;
            transition: all .3s;
            cursor: pointer;

            >.img2 {
                width: 16px;
                height: 16px;
                position: absolute;
                top: 8px;
                right: 10px;

                >img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            >.img {
                width: 200px;
                height: 200px;

                >img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            >.name {
                width: 100%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                font-size: 14px;
                color: #000;
                margin-top: 10px;
            }


            >.jiag {
                width: 100%;
                display: flex;
                align-items: center;
                margin-top: 5px;

                >.jg {
                    color: #FF4242;
                    font-size: 16px;
                    font-weight: bold;
                }

                >.xl {
                    color: #666666;
                    font-size: 12px;
                    margin-left: 10px;
                }
            }
        }

        .mrdz {
            width: 100px;
            display: flex;
            font-size: 12px;
            color: #666;
            cursor: pointer;
            user-select: none;
            margin-top: 10px;

            >.text {
                margin-left: 5px;
            }
        }

        .tn {
            width: 90px;
            height: 30px;
            background-color: #FF4242;
            border-radius: 1px 1px 1px 1px;
            color: #fff;
            font-size: 14px;
            text-align: center;
            line-height: 30px;
            cursor: pointer;
        }
    }

}

.paging {
    width: 100%;
    height: 50px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.managementClass {
    border: 1px solid #E0E0E0 !important;
}


/deep/ .el-tabs__nav-wrap::after {
    background-color: #fff;
}

/deep/ .el-tabs__item.is-active {
    color: #FF4242;
}

/deep/ .el-tabs__active-bar {
    background-color: #FF4242;
}

/deep/ .el-tabs__item:hover {
    color: #FF4242;
}


// 分页样式
/deep/ .el-pagination .active {
    background-color: #ff4242 !important;
    transition: all .3s !important;
}

/deep/ .el-pagination:hover .active:hover {
    color: #fff !important;
    transition: all .3s !important;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled) {
    background-color: #fff;
    border: 1px solid #fff;
    transition: all .3s !important;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #ff4242;
    border: 1px solid #ff4242;
    transition: all .3s !important;
}

/deep/ .el-icon-arrow-right:hover {
    color: #ff4242 !important;
    transition: all .3s !important;
}

/deep/ .el-icon-arrow-left:hover {
    color: #ff4242 !important;
    transition: all .3s !important;
}
</style>